<template>
  <div>
    <div v-for='selectableOption in selectableOptions'
      :key='`selectableOption-${selectableOption.id}-${selectableOption.value}`'
      class='mb-1'>
      <label>
        <input type='checkbox'
          class='mr-1'
          :value='selectableOption.value'
          v-model='selectValues'>{{selectableOption.title}}
      </label>
    </div>
    <div v-if='hasCustomInput' class='mb-1'>
      <label>
        <input type='checkbox'
          class='mr-1'
          v-model='showCustomInput'>{{ hasCustomInput.title }}
      </label>
    </div>
    <input
      v-if='showCustomInput'
      v-model='customInputValue'
      class='border py-1 px-2 rounded block w-full mt-2'
      type='text'
      :placeholder='placeholder'>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldMultiSelect',
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      selectValues: [],
      showCustomInput: false,
      customInputValue: '',
    }
  },
  watch: {
    'value': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.selectValues = newVal
        }
      },
      immediate: true,
      deep: true
    },
    'selectValues': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:selectValues', newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'showCustomInput': {
      handler: function (newVal, oldVal) {
        if (!newVal && newVal !== oldVal) {
          let index = this.selectValues.findIndex(value => value === this.customInputValue)
          if (index > -1) {
            this.selectValues.splice(index, 1)
            this.customInputValue = ''
          }
        }
      },
      immediate: true,
    },
    'customInputValue': {
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (oldVal) {
            let index = this.selectValues.findIndex(value => value === oldVal)
            this.selectValues.splice(index, 1, newVal)
          } else {
            this.selectValues.push(newVal)
          }
        } else {
          let index = this.selectValues.findIndex(channel => channel === oldVal)
          if (index > -1) {
            this.selectValues.splice(index, 1)
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    selectableOptions () {
      return this.metaData && this.metaData.selectableOptions ? this.metaData.selectableOptions.filter(option => option.value !== 'other') : []
    },
    hasCustomInput () {
      return this.metaData && this.metaData.selectableOptions ? this.metaData.selectableOptions.find(option => option.value === 'other') : false
    },
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  }
}
</script>
